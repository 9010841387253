<template lang="html">
    <section class="events">
        <section class="container">
            <section class="container-grid" v-if="eventsCalendar">
                <div class="grid-small">
                    <p class="title-dest-underline">Calendario</p>
                    <template v-if="eventsCalendar">
                        <calendar :events="eventsCalendar" @datesCalendar="getEventsCalendar"></calendar>
                    </template>
                   
                </div>
                <div class="grid-big">
                    <p class="title-dest-underline">Próximos eventos</p>
                    <template v-if="events">
                        <template v-for="(event, key ) in events" :key="key">
                            <event50img v-if="key == 0" :dataContent="event"></event50img>
                        </template>
                    </template>
                    <div v-else class="container-noResult">
                        <p class="title-dest-underline">No hay resultados</p>
                    </div>
                </div>
            </section>
            <template v-if="events">
                <section class="grid-3">
                    <template v-for="(event, key ) in events" :key="key">
                        <eventViewFull v-if="key != 0" :dataContent="event"></eventViewFull>
                    </template>
                </section>
                <paginator :pageActual="page"  @changed="changePages" :totalItems="EventsMaxItems" @clicked="changePages"></paginator>
            </template>
            <!-- <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div> -->

        </section>
    </section>
</template>

<script lang="js">
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import store from '@/store';
    import eventViewFull from "@/components/events/event-view-full.vue";
    import event50img from "@/components/events/event-view-50-img.vue";
    import calendar from "@/components/events/calendar.vue";
    import paginator from "@/components/paginator.vue";
    import utils from "@/utils/utils.js";
    import moment from 'moment'
    export default {
        name: 'events',
        components: {
            eventViewFull,
            event50img,
            calendar,
            paginator,
        },
        props: ['filterTime'],
        mounted() {
            this.loadEvents({ page: this.page }),
            this.loadEventsCalendar({
                filtersResults: {
                    'starDate': this.startDayOfMonth,
                    'endDate': this.endDayOfMonth
                }
            })
        },
        data() {
            return {
                date: new Date(),
                startDate: moment(this.date).format('YYYY-MM-DD'),
                startDayOfMonth: moment(this.startDate).startOf('month').format('YYYY-MM-DD'),
                endDayOfMonth: moment(this.startDate).endOf('month').format('YYYY-MM-DD'),
            }
        },
        computed: {
            ...mapGetters({
                getPagesEvents: 'contents/getPagesEvents',
                page: 'contents/getEventsViewPage',
            }),
            events: function () {
                return store.getters['contents/getEvents'](this.page)
            },
            eventsCalendar: function () {
                return store.getters['contents/getEventCalendar']
            },
            EventsMaxItems() {
                return this.getPagesEvents();
            }
        },
        methods: {
            ...mapActions({
                loadEvents: 'contents/loadEvents',
                loadEventsCalendar: 'contents/loadEventsCalendar'
            }),
            ...mapMutations({
                setPageEvents: 'contents/setPageEvents',

            }),
            changePages(item) {
                this.loadEventsCalendar()
                this.setPageEvents(item)
                this.loadEvents({
                    page: this.page,
                });
                utils.LoaderScrollToTop();
            },
            getEventsCalendar(item) {
                this.loadEventsCalendar({
                    filtersResults: {
                        'starDate': item.min,
                        'endDate': item.max
                    }
                })
            }
        },

        watch: {
            filterTime: function (newVal, oldVal) {
                this.loadEvents({ page: 1 });
                this.loadEventsCalendar()
            }
        }
    }
</script>

<style scoped lang="scss">
    .events {
        .container-grid .grid-small {
            width: 35%;
            padding-right: 20px;
        }

        .container-grid .grid-big {
            padding-left: 20px;
            width: 65%;
        }

    }

    @media screen and (max-width:845px) {
        .events .container-grid .grid-small {
            width: 100%;
            padding-right: 0px;
        }

        .events .container-grid .grid-big {
            padding-left: 0px;
            width: 100%;
        }
    }
</style>