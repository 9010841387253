<template lang="html">
    <section class="calendar" v-if="events">
        <Calendar is-expanded :attributes='attributes' ref="calendar">
            <template #day-popover="{ day}">
                <div v-for="event in day.attributesMap" :key="event">
                    <router-link
                        :to="{name: 'page-entry', params: {typeContent: event.customData.type,slug: event.customData.slug,}, }"
                        class="content-popup" title="Enlace al evento"> {{event.customData.description}}
                    </router-link>
                </div>
            </template>

        </Calendar>
        <button type="button"  title="Ver eventos del mes anterior" aria-label="Ver eventos del mes anterior" class="prev-month" @click="monthPrev">Mes anterior</button>
        <button type="button"  title="Ver eventos del mes siguiente" aria-label="Ver eventos del mes siguiente" class="next-month" @click="monthNext">Mes siguiente</button>
    </section>

</template>

<script lang="js">
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import store from '@/store';
    import {
        Calendar,
        DatePicker
    } from 'v-calendar';
    import moment from 'moment'
    export default {
        name: 'calendar',
        props: ['events'],
        mounted() {
            this.eventCalendar()

        },
        components: {
            Calendar,
            //DatePicker,
        },
        data() {
            const todos = [];
            return {
                date: new Date(),
                startDate: moment(this.date).format('YYYY-MM-DD'),
                startDayOfMonth: moment(this.startDate).startOf('month').format('YYYY-MM-DD'),
                endDayOfMonth: moment(this.startDate).endOf('month').format('YYYY-MM-DD'),
            }
        },
        methods: {
            currentDateYear(date) {
                return moment(date).locale('es').format('Y')
            },
            currentDateMonth(date) {
                return moment(date).locale('es').format('M') - 1
            },
            currentDateDay(date) {
                return moment(date).locale('es').format('D')
            },

            eventCalendar() {
                let dates = [];
                Object.entries(this.events).forEach(([key, element]) => {
                    console.log(element.start_date);
                    let pointCalendar = {
                        description: element.name,
                        isComplete: false,
                        dates: [
                            new Date(this.currentDateYear(element.start_date), this.currentDateMonth(element.start_date), this.currentDateDay(element.start_date)),
                        ], // Every Friday
                        color: 'red',
                        slug: element.slug,
                        type: 'event'
                    }
                    dates.push(pointCalendar)
                });
                return dates;
            },
            monthPrev() {
                this.startDate = moment(this.startDate).subtract(1, 'months').format('YYYY-MM-DD')
                this.startDayOfMonth = moment(this.startDate).startOf('month').format('YYYY-MM-DD')
                this.endDayOfMonth = moment(this.startDate).endOf('month').format('YYYY-MM-DD')
                this.$refs.calendar.move(-1)
                this.$emit('datesCalendar', {
                    'min': this.startDayOfMonth,
                    'max': this.endDayOfMonth
                });
            },
            monthNext() {
                this.startDate = moment(this.startDate).add(1, 'months').format('YYYY-MM-DD')
                this.startDayOfMonth = moment(this.startDate).startOf('month').format('YYYY-MM-DD')
                this.endDayOfMonth = moment(this.startDate).endOf('month').format('YYYY-MM-DD')
                this.$refs.calendar.move(1)
                this.$emit('datesCalendar', {
                    'min': this.startDayOfMonth,
                    'max': this.endDayOfMonth
                });
            }

        },

        computed: {
            CalculateStartOfMonth: function () {
                return this.startDayOfMonth
            },
            calendarData: function () {
                return this.eventCalendar()
            },
            calendarDataLength: function () {
                return this.calendarData.length()
            },
            attributes() {
                let array = [];
                this.calendarData.map(date => {
                    array.push({
                        dates: date.dates,
                        highlight: 'red',
                        popover: true,
                        customData: date,
                    })
                });
                return array;
            },
        },
        watch: {
            'this.events'() {
                this.eventCalendar()

            }
        }

    }
</script>

<style scoped lang="scss">
    .calendar {
        margin-bottom: 30px;
        position: relative;
        width: 100%;

        .prev-month {
            background-image: url(/img/icons/arrow-right.svg);
            background-color: transparent;
            font-size: 0;
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 30px;
            position: absolute;
            top: 5px;
            left: 12px;
            border: 0;
            cursor: pointer;
            transform: rotate(180deg);
        }

        .next-month {
            background-image: url(/img/icons/arrow-right.svg);
            background-color: transparent;
            font-size: 0;
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 30px;
            position: absolute;
            top: 5px;
            right: 12px;
            border: 0;
            cursor: pointer;


        }
    }
</style>